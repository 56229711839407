import React, { Component } from "react"
import css from "./DLSimpleList.module.scss"
import { StaticQuery, graphql } from "gatsby"
import DLSimpleListItem from "./DLSimpleListItem"

class DLSimpleList extends Component {
  constructor(data) {
    super()
    this.state = {
      sort: `random`,
      randomUpdate: 0,
      randomSortLock: false,
    }
    this.data = data
    this._doSort = this._doSort.bind(this)
    // this._doMouseOutRandom = this._doMouseOutRandom.bind(this)
    this._doRandomSort = this._doRandomSort.bind(this)

    this._queryPosts = []
    for (let i = 0; i < this.data.site.allMarkdownRemark.edges.length; i += 1) {
      let enty = this.data.site.allMarkdownRemark.edges[i]
      if (enty.node.fields.langKey === data.lang) {
        this._queryPosts.push(enty)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this._randInterval)
  }
  componentDidMount() {
    this._shufflePosts()
  }

  /**
   *
   * @param e
   * @private
   */
  _doSort(e) {
    this._stopDestinyRandom()
    const value = e.currentTarget.dataset.sortValue
    this.setState(state => ({
      sort: value,
      randomSortLock: false,
    }))
  }

  _doRandomSort(e) {
    const value = e.currentTarget.dataset.sortValue
    if (this.state.sort !== `random`) {
      // ランダムが選ばれた場合は必ずデスティニー状態に
      this.setState(state => ({
        sort: value,
        randomSortLock: true,
      }))
      this._doDestinyRandom()
    } else {
      const isRandomAnimation = !this.state.randomSortLock
      this.setState(state => ({
        sort: value,
        randomSortLock: isRandomAnimation,
      }))
      if (isRandomAnimation) {
        this._doDestinyRandom()
      } else {
        this._stopDestinyRandom()
      }
    }
  }

  /**
   *
   * @param e
   * @private
   */
  _doDestinyRandom() {
    clearInterval(this._randInterval)
    this._randInterval = setInterval(() => {
      this._shufflePosts()
    }, 1000 / 16)
  }

  _shufflePosts() {
    this._queryPosts.sort(function () {
      return Math.random() - 0.5
    })

    this.setState(state => ({
      randomUpdate: Math.random(),
    }))
  }

  /**
   *
   * @param e
   * @private
   */
  _stopDestinyRandom() {
    clearInterval(this._randInterval)
  }

  /**
   *
   * @returns {*}
   */
  render() {
    const clsBtnRand = this.state.sort === "random" ? css.sortButtonActive : ""
    const clsBtnPrice = this.state.sort === "price" ? css.sortButtonActive : ""
    const clsBtnName = this.state.sort === "name" ? css.sortButtonActive : ""
    const clsBtnRandLock = this.state.randomSortLock
      ? css.sortButtonActiveAndLock
      : ""

    let entries = this._queryPosts

    if (this.state.sort === `name`) {
      entries.sort((a, b) => {
        const nameA = a.node.frontmatter.alphabetical.toUpperCase()
        const nameB = b.node.frontmatter.alphabetical.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    } else if (this.state.sort === `price`) {
      entries.sort((a, b) => {
        const nameA = a.node.frontmatter.priceSort
        const nameB = b.node.frontmatter.priceSort
        if (nameA < nameB) {
          return 1
        }
        if (nameA > nameB) {
          return -1
        }
        return 0
      })
    }

    return (
      <div className={css.base}>
        <div className={css.sort}>
          <div>Sort by</div>
          <div>
            <button
              data-sort-value={`random`}
              className={
                css.sortButton + ` ` + clsBtnRand + ` ` + clsBtnRandLock
              }
              onPointerDown={this._doRandomSort}
              onFocus={() => void 0}
            >
              <span role="img" aria-label="" className={css.destinyIcon}>
                💕
              </span>
              Destiny
            </button>
          </div>
          <div>
            <button
              data-sort-value={`price`}
              className={css.sortButton + ` ` + clsBtnPrice}
              onClick={this._doSort}
            >
              Price
            </button>
          </div>
          <div>
            <button
              data-sort-value={`name`}
              className={css.sortButton + ` ` + clsBtnName}
              onClick={this._doSort}
            >
              <span className={`onlyPc`}>Artist</span> Name
            </button>
          </div>
        </div>
        {this._queryPosts.map(({ node }) => (
          <div className={css.listItem} key={node.id}>
            <div className={css.listItemInner}>
              <DLSimpleListItem
                title={node.frontmatter.title}
                date={node.frontmatter.date}
                artistName={node.frontmatter.artistName}
                alphabetical={node.frontmatter.alphabetical}
                artistTitle={node.frontmatter.artistTitle}
                size={node.frontmatter.size}
                excerpt={node.excerpt}
                sizeUnit={node.frontmatter.sizeUnit}
                medium={node.frontmatter.medium}
                thumbImg={node.frontmatter.thumbImg}
                linkTo={node.fields.slug}
                price={node.frontmatter.price}
                sortMode={this.state.sort}
              />
            </div>
          </div>
        ))}
      </div>
    )
  }
}
export default prop => {
  const lang = !prop.lang ? `ja` : prop.lang
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark {
            edges {
              node {
                frontmatter {
                  date
                  title
                  artistName
                  alphabetical
                  artistTitle
                  price
                  priceSort
                  size
                  sizeUnit
                  medium
                  thumbImg {
                    childImageSharp {
                      fluid(maxWidth: 400, quality: 95) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                fields {
                  slug
                  langKey
                }
                excerpt(truncate: true)
                timeToRead
                html
                id
              }
            }
          }
        }
      `}
      render={data => (
        <DLSimpleList site={data} lang={lang}>
          {prop.children}
        </DLSimpleList>
      )}
    />
  )
}
