import React from "react"
import { Link } from "gatsby"
import css from "./DLSimpleListItem.module.scss"
import Img from "gatsby-image"

const DLSimpleListItem = prop => {
  let initialStr = prop.alphabetical.substr(0, 2)
  let alphabetical = ""
  let priceClass = css.price
  if (prop.sortMode === "name") {
    alphabetical = <span className={css.initial}>{initialStr}</span>
  } else if (prop.sortMode === "price") {
    priceClass = css.price + ` ` + css.priceIsActive
  }

  let excerpt = prop.excerpt

  let thumbImgFluid = prop.thumbImg.childImageSharp.fluid

  return (
    <Link
      className={css.colWrap}
      to={prop.linkTo}
      state={{
        modal: true,
      }}
    >
      <div className={css.colLeft}>
        <Img fluid={thumbImgFluid} />
      </div>
      <div className={css.colCenter}>
        <h3 className={css.title}>
          <span>{prop.title}</span>
        </h3>
        <p className={css.medium}>
          {prop.medium} / {prop.size}
          <small>{prop.sizeUnit}</small> {/* / {prop.date} */}
        </p>
        <p className={css.name}>
          {alphabetical}
          {prop.artistName} <small>({prop.artistTitle})</small>
        </p>
        <p className={css.excerpt}>{excerpt}</p>
      </div>
      <div className={css.colRight}>
        <p className={priceClass}>
          <small>{prop.price}</small>
        </p>
      </div>
    </Link>
  )
}

export default DLSimpleListItem
