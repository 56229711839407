import { Link } from "gatsby"
import React, { Component } from "react"
import css from "./HeaderSub.module.scss"

class HeaderSub extends Component {
  render() {
    const gridClassActive = this.props.mode === `grid` ? css.active : ""
    const listClassActive = this.props.mode === `list` ? css.active : ""

    const langDir = this.props.lang === `en`? `/en`:``;

    return (
      <nav className={css.base}>
        <ul className={css.mode}>
          <li>
            <Link to={`${langDir}/downloads/`} className={gridClassActive}>
              Grid
            </Link>
          </li>
          <li>
            <Link to={`${langDir}/downloads/list/`} className={listClassActive}>
              List
            </Link>
          </li>
        </ul>
      </nav>
    )
  }
}

export default HeaderSub
