import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import css from "./header.module.scss"
import Lottie from "react-lottie"
import * as animationData from "../images/lottie/hLogo.json"

class Header extends Component {
  constructor() {
    super();

    this._lottieOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      },
      className: `ascrg`
    }

  }


  render() {
    const langDir = this.props.lang === `en` ? `/en` : ``
    let lang =
      this.props.lang === `en` ? (
        <Link to={`/downloads/`}>日本語</Link>
      ) : (
        <Link to={`/en/downloads/`}>English</Link>
      )

    return (
      <header className={css.base}>
        <h1>
          <Link to={`${langDir}/downloads`}>
            <Lottie options={this._lottieOptions} height={18} width={300} />
          </Link>
        </h1>
        <div className={css.language}>{lang}</div>
      </header>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
