/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Component } from "react"
import Header from "./header"

class Layout extends Component {
  render() {
    const lang = this.props.lang? this.props.lang: `ja`
    return (
      <div>
        <Header siteTitle={`TRANS BOOKS DOWNLOADs`} mode={this.props.mode} lang={lang} />
        <div>
          <main>{this.props.children}</main>
        </div>
      </div>
    )
  }
}
export default Layout
